import moment from 'moment-timezone';

export default {
  methods: {
    utilityMixin_getTimezone() {
      return localStorage.getItem('timezone') || 'Asia/Jakarta';
    },
    utilityMixin_formatDateTime(date) {
      const m = moment(date, 'YYYY-MM-DD');
      return m.isValid() ? m.format('DD-MMM-YYYY') : '-';
    },
    utilityMixin_formatSDateTime(date) {
      const m = moment(date);
      return m.isValid() ? m.format('YYYY-MM-DD') : '-';
    },
    utilityMixin_splitDate(dateArray = [], startKey = '', endKey = '') {
      return dateArray?.length == 2
        ? {
            [startKey]: dateArray[0],
            [endKey]: dateArray[1],
          }
        : null;
    },
    utilityMixin_splitSampleId(input) {
      return input.split('\r\n');
    },
    utilityMixin_disabledDateMaxNow(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    utilityMixin_scrollTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    },
    utilityMixin_mappingValue(filters, mapping) {
      return Object.keys(filters).reduce((acc, key) => {
        acc[mapping?.[key] ?? key] = filters[key];
        return acc;
      }, {});
    },
    utilityMixin_deleteKeys(item = {}, keys = []) {
      keys.forEach(function(value) {
        delete item[value];
      });
      return item;
    },
  },
};
