import router from '../../router';

const namespaced = true;

const state = {
  token: '',
  refreshToken: '',
  user: {
    id: null,
    username: '',
  },
};

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  user: state => state.user,
};

const mutations = {
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
  SET_USER: (state, payload) => {
    state.user = { ...state.user, ...payload };
  },
  CHANGE_USER_FORCE_RESET: (state, payload) => {
    state.user.needs_reset_password=payload;
  },
  CLEAR: state => {
    state.token = null;
    state.refreshToken = null;
    state.user = {};
  },
};

const actions = {
  async login({ commit }, data) {
    return await window.axios
      .post('/auth/token/login/', data)
      .then(res => {
        commit('SET_TOKEN', res.data.auth_token);
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getAccountInfo({ commit }) {
    return window.axios
      .get('/auth/users/me/')
      .then(res => {
        commit('SET_USER', res.data);
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  getAccountSetting({ commit }, id) {
    return window.axios
      .get(`/user_account_profile/api/accounts/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(err);
      });
  },
  // eslint-disable-next-line no-unused-vars
  getSubscribed({ commit }, id) {
    return window.axios
      .get(`/customer_file/api/emails/${id}/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(err);
      });
  },
  // eslint-disable-next-line no-unused-vars
  activation({ commit }, data) {
    return window.axios
      .post('/auth/users/activation/', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  reactivation({ commit }, data) {
    return window.axios
      .post('/auth/users/resend_activation/', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  updateUserPasswordForce({ commit }) {
    return window.axios
      .post('/user_account_profile/api/verify-reset-password/')
      .then(res => {
        commit('CHANGE_USER_FORCE_RESET', false);
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  patchAccountSetting({ commit }, data) {
    return window.axios
      .put(`/user_account_profile/api/accounts/${data.id}/`, data.form)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  patchSubscribed({ commit }, data) {
    return window.axios
      .put(`/customer_file/api/emails/${data.id}/`, data.form)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  signup({ commit }, data) {
    return window.axios
      .post('/auth/users/', data)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit }, data) {
    return window.axios
      .post('/auth/users/reset_password/', data)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(err.response.data[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  newPassword({ commit }, data) {
    return window.axios
      .post('/auth/users/reset_password_confirm/', data)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  // eslint-disable-next-line no-unused-vars
  changePassword({ commit }, data) {
    return window.axios
      .post('/auth/users/set_password/', data)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  refresh({ commit }) {
    commit('CLEAR');
    router.push({ name: 'AuthLogin' }).catch();
  },
  logout({ commit }) {
    return window.axios
      .post('/auth/token/logout/')
      .then(() => {
        commit('CLEAR');
      })
      .catch(() => {
        commit('CLEAR');
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
