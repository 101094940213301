<template>
  <div class="canvas">
    <v-card outlined class="custom-card rounded-lg">
      <div class="d-block text-center">
        <svg-icon icon="no-data-icon" />
      </div>
      <div class="d-block text-center mt-10 message">
        {{ message }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      styleObject: {},
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas {
  background-color: #ffffff;
}
.custom-card {
  border: 1px solid #73acd9;
  padding: 50px;
}
.message {
  color: rgba(22, 50, 92, 0.6);
}
</style>
