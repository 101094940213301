<template>
  <v-menu
    top
    :offset-y="true"
    nudge-right="16px"
    transition="slide-y-transition"
    :close-on-content-click="false"
    class="fab-help"
    ref="fabMenuRef"
  >
    <template #activator="{ on }">
      <svg-icon icon="help-button" v-on="on" class="fab-help fab-button" />
    </template>

    <v-card class="mx-auto rounded-lg" width="300" max-height="500">
      <v-card-text class="pa-4 text-center">
        <p class="text-main text-left">
          {{ $t('content.help_for_this_page') }}
        </p>
        <v-alert dense text type="info" color="orange" class="pa-1 mb-3 rounded-lg text-left">
          {{ $t('content.enter_keywords_help') }}
        </v-alert>
        <v-autocomplete
          v-model="select"
          :loading="searchLoading"
          :items="searchResults"
          :search-input.sync="search"
          return-object
          item-text="title"
          item-value="id"
          class="rounded-lg mb-3"
          prepend-inner-icon="mdi-magnify"
          append-icon=""
          flat
          no-filter
          hide-no-data
          hide-details
          outlined
          dense
          clearable
          :placeholder="$t('content.help_placeholder')"
          @click:clear="reset"
        >
          <template #item="{ item }">
            <v-list-item-content class="item-select">
              <v-list-item-title class="item-select-content">
                <b>{{ item.title }}</b>
              </v-list-item-title>
              <v-list-item-subtitle v-text="item.body" />
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions class="pa-4 card-actions">
        <v-btn outlined color="primary" class="rounded-lg pa-4" small @click="goToHelpCenter">
          {{ $t('content.go_to_help') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import SvgIcon from '../SvgIcon.vue';

export default {
  components: { SvgIcon },
  name: 'HelpCenterFab',
  data() {
    return {
      search: null,
      select: null,
      searchLoading: false,
    };
  },
  watch: {
    search: debounce(function(val) {
      if (val) {
        this.searchLoading = true;
        this.$store.dispatch('HelpCenter/search', { search: val }).finally(() => (this.searchLoading = false));
      } else this.$store.commit('HelpCenter/RESET_SEARCH_RESULTS');
    }, 300),
    select(val) {
      if (val) this.goToHelpCenter(val);
    },
  },
  computed: {
    ...mapGetters({
      searchResults: 'HelpCenter/searchResults',
    }),
  },
  methods: {
    goToHelpCenter(val) {
      const context_id = val.id;
      const topic_id = val.topic;
      this.$store.commit('HelpCenter/RESET_SEARCH_RESULTS');
      this.$refs.fabMenuRef.save();
      const route = this.$router.resolve({
        name: `HelpCenter`,
        query: { tab: 'self-help', topic_id, context_id },
      });
      window.open(route.href, '_blank');
    },
    reset() {
      this.$store.commit('HelpCenter/RESET_SEARCH_RESULTS');
    },
  },
};
</script>

<style lang="scss" scoped>
.fab-help {
  z-index: 999;
}

.fab-button {
  position: fixed;
  bottom: 10px;
  cursor: pointer;
}

.text-main {
  color: #16325c;
  font-weight: 600;
  font-family: Arial;
  font-size: 17px;
}

.card-actions {
  border-top: 1px solid #efeee6;
}

.item-select {
  max-width: 300px;
  position: relative;

  .item-select-content {
    width: 100%;
  }
}

::v-deep .v-alert__wrapper > .v-icon {
  margin-right: 5px;
}

::v-deep .v-alert__content {
  color: #999a9b;
  font-size: 13px;
  line-height: 19px;
}
</style>
