<template>
  <v-app class="bg arialMT">
    <v-navigation-drawer v-model="drawer" color="#0070CC" app>
      <v-img src="@/assets/images/background/drawer-background.png" alt="" height="100%">
        <div class="drawer-logo pt-4" @click="goToDashboard">
          <SvgIcon icon="idfl" class="ml-4" />
        </div>
        <div class="line-spacer"></div>

        <v-list rounded class="pl-5 drawer-list">
          <v-list-item-group>
            <v-list-item
              v-for="(menu, index) in menus"
              :key="index"
              :to="menu.url"
              class="mt-2 pl-3"
              style="height: 44px;"
              active-class="bg-active"
            >
              <v-list-item-icon class="mr-3 ml-0 my-auto">
                <SvgIcon :icon="`menu/${menu.icon}`" class="icon-svg" />
              </v-list-item-icon>
              <v-list-item-title class="drawer-title">
                {{ $t(menu.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-img>
    </v-navigation-drawer>

    <v-app-bar color="white" class="navbar" app>
      <v-row align="center">
        <v-app-bar-nav-icon class="d-lg-none" light @click="drawer = true" />
        <div v-if="getIcon !== 'page-not-found'" class="text-h5 d-none d-sm-flex align-center title pr-2">
          <div class="title-icon">
            <SvgIcon :icon="`menu/${getIcon}`" class="icon-svg" width="20px" height="20px" />
          </div>
          {{ getTitle }}
        </div>

        <!-- notification chip -->
        <v-chip v-if="isNotificationPage" class="ml-3 px-2 rounded-lg white--text" color="blue accent-1" small label>
          <!-- TODO: add notification count here -->
          {{ notificationUnreads }}
        </v-chip>
        <v-spacer />

        <v-btn icon @click="goToHelp">
          <svg-icon icon="icon-help-center" />
        </v-btn>

        <v-btn icon @click="goToNotification">
          <template v-if="notificationUnreads > 0 || currentNotification">
            <svg-icon icon="icon-notif" />
          </template>
          <template v-else>
            <svg-icon icon="icon-no-notif" />
          </template>
        </v-btn>

        <v-menu offset-y nudge-bottom="10px">
          <template #activator="{ on, attrs }">
            <div class="d-flex align-center user-detail-container" v-bind="attrs" v-on="on">
              <v-avatar class="mr-2" height="28" width="28">
                <img src="@/assets/images/user-profile.png" alt="User Profile" />
              </v-avatar>
              <div class="user-detail">
                <div class="body-1 font-weight-bold t-user">
                  {{ user.first_name }} {{ user.last_name }}
                  <svg-icon icon="icon-arrow-glyph-down" class="ml-3" />
                </div>
                <div class="caption t-cap text-left">
                  {{ $t('content.client') }}
                </div>
              </div>
            </div>
          </template>
          <v-list class="pb-4">
            <!--
            Djoser is having problems sending email on every change instead of
            select changes. Changing this until the update with that fix comes
            <v-list-item class="pt-2">
              <v-btn @click.native="goToProfile" text block class="menu-button justify-start">
                <img src="@/assets/images/profile-icon.png" alt="logout" class="mx-1 icon-width" />
                {{ $t('account.account_profile') }}
              </v-btn>
            </v-list-item>-->
            <v-list-item>
              <v-btn @click.native="goToSetting" text block class="menu-button justify-start">
                <img src="@/assets/images/setting-icon.png" alt="logout" class="mx-1 icon-width" />
                {{ $t('account.account_setting') }}
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text block class="logout-button justify-start" @click="showLogoutDialog = true">
                <img src="@/assets/images/logout_icon.png" alt="logout" class="ml-2 mr-1 icon-logout-width" />
                {{ $t('signUpContent.logout') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid :class="{ 'pa-0 h-100': isNotificationPage }">
        <div :class="{ 'pa-2': !isNotificationPage, 'h-100': isNotificationPage }">
          <router-view :is-show-sidebar="drawer" />
        </div>
      </v-container>
    </v-main>

    <HelpCenterFab />
    <BaseScrollTop />

    <v-dialog v-model="showLogoutDialog" class="arialMT" max-width="500">
      <v-card>
        <v-card-text>
          <div class="pt-10">
            <v-img class="mx-auto" src="@/assets/images/logout-icon.png" width="170px" />
          </div>
          <div class="text-h5  indigo--text font-weight-bold text--darken-4  pa-4 pt-10 text-center">
            {{ $t('account.logout_confirm') }}
          </div>
          <div class="indigo--text text--darken-2  px-4 text-center">
            {{ $t('account.logout_body') }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-8">
          <v-btn width="100px" class=" mr-6 no-transform" outlined color="indigo" @click="showLogoutDialog = false">
            {{ $t('account.no') }}
          </v-btn>

          <v-btn class="primary ml-6 no-transform" width="100px" @click="signOut">
            {{ $t('account.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { MENUS as menus } from '@/constant/menus.constant';
import HelpCenterFab from '@/components/help-center/HelpCenterFab';
import BaseScrollTop from '@/components/base/BaseScrollTop';

export default {
  name: 'DefaultLayout',
  components: {
    HelpCenterFab,
    BaseScrollTop,
  },
  data: () => ({
    menus,
    drawer: true,
    showLogoutDialog: false,
    connection: null,
  }),
  computed: {
    ...mapGetters({
      user: 'Auth/user',
      token: 'Auth/token',
      currentNotification: 'Notification/currentNotification',
      notificationUnreads: 'Notification/notificationUnreads',
    }),
    getTitle() {
      return this.$t(this.$route.meta?.title);
    },
    getIcon() {
      return this.$route.meta?.icon;
    },
    isNotificationPage() {
      return this.$route.name === 'Notifications';
    },
  },
  methods: {
    fetchNotifications() {
      this.$store.dispatch('Notification/fetchNotifications');
    },
    fetchNotificationUnreads() {
      this.$store.dispatch('Notification/fetchNotificationUnreads');
    },
    signOut() {
      this.$store.dispatch('Auth/logout').then(() => {
        this.$router.push({ name: 'AuthLogin' });
      });
    },
    getAccountProfile() {
      const userId = this.$store.getters['Auth/user'].account_id;
      this.$store
        .dispatch('Auth/getAccountSetting', userId)
        .then(res => {
          this.$i18n.locale = res.language;
          localStorage.setItem('lang', res.language);
          localStorage.setItem('timezone', res.timezone);
        })
        .catch(err => this.$toast.error(err.message));
    },
    goToDashboard() {
      this.$router.push({ name: 'Dashboard' }).catch(() => {});
    },
    goToProfile() {
      this.$router.push({ name: 'AccountProfile' }).catch(() => {});
    },
    goToSetting() {
      this.$router.push({ name: 'AccountSetting' }).catch(() => {});
    },
    goToNotification() {
      this.$router.push({ name: 'Notifications' }).catch(() => {});
    },
    goToHelp() {
      this.$router.push({ name: 'HelpCenter' }).catch(() => {});
    },
  },
  created() {
    this.getAccountProfile();
    this.fetchNotifications();
    this.fetchNotificationUnreads();

    this.connection = new WebSocket(`${process.env.VUE_APP_SOCKET_URL}?token=${this.token}`);

    this.connection.onmessage = e => {
      const { notification, unread_notifications, read_at } = JSON.parse(e.data);
      this.$store.commit('Notification/SET_NOTIFICATION_UNREADS', unread_notifications);

      // Show toast if read_at none
      if (read_at === 'None') {
        this.$toast.info(notification.message, {
          onClick: () => {
            this.$router.push({ name: 'Notifications' }).catch(() => {});
          },
        });

        // If current page is notication reload again
        if (this.$route.name === 'Notifications') {
          this.$store.dispatch('Notification/fetchNotifications');
        }
      }
    };

    if (process.env.NODE_ENV === 'development') {
      this.connection.onopen = () => console.log('socket successfully connect');
      this.connection.onerror = () => console.log('Socket.error');
      this.connection.onclose = () => console.log('Socket.closed');
    }
  },
  beforeDestroy() {
    this.connection.close();
  },
  beforeMount(){
    if(this.user.needs_reset_password){
      this.$router.push({name: 'ForceResetPassword'});
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
}

.title {
  margin-left: 20px;
  color: #0060af;
  font-size: 18px !important;
  font-weight: bolder;
}

.title-icon {
  background-color: #0060af;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.icon-width {
  width: 19px;
}

.icon-logout-width {
  width: 15px;
}

.menu-button {
  text-transform: none;
  color: #005fad !important;
}

.logout-button {
  text-transform: none;
  color: red !important;
}

.line-spacer {
  border-bottom: 1px solid #ffffff9c;
  margin: 10px 14px 20px 14px;
}

.drawer-list {
  padding-top: 10px;
  margin-right: -40px;
}

.drawer-title {
  font-size: 14px;
}

.no-transform {
  text-transform: none;
}

.bg-normal {
  color: white !important;
}

.icon-svg {
  filter: brightness(0) invert(1);
}

.bg-active {
  background-color: white !important;
  color: #0060af !important;

  .icon-svg {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%);
  }
}

.drawer-logo {
  cursor: pointer;
}

.user-detail-container {
  margin-right: 12px;
}

@include media-breakpoint-down(md) {
  .user-detail-container {
    margin-right: 0;

    .user-detail {
      display: none;
    }
  }
}
.bg {
  background-color: #ffffff !important;
  font-size: 15px;
}

.t-user {
  font-size: 13px !important;
  color: #42597c !important;
}

.t-cap {
  font-size: 12px !important;
  color: #617188 !important;
}

::v-deep .v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  display: none;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: white !important;
}

.theme--light.v-icon {
  color: inherit !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  opacity: 0;
}
</style>
