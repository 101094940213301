<template>
  <div class="paging-container mt-2">
    <div class="data-per-page d-flex align-center justify-center">
      {{ $t('content.show') }}
      <v-select
        outlined
        dense
        :items="options"
        v-model="perPage"
        required
        hide-details
        class="mx-2 per-page-selector rounded-lg"
      />
      {{ $t(contentText) }}
    </div>
    <v-spacer />
    <v-pagination
      class="mt-3"
      color="primary-light"
      :value="page"
      :length="length"
      :total-visible="7"
      @input="onChangePagination"
    />
    <v-spacer />
  </div>
</template>

<script>
import utilityMixin from '@/mixins/utilityMixin';

export default {
  mixins: [utilityMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    customLength: {
      type: Boolean,
      default: false,
    },
    customLengthFunc: {
      type: Function,
      default: null,
    },
    contentText: {
      type: String,
      default: 'content.data',
    },
  },
  data: () => ({
    page: 1,
    perPage: 10,
    options: [5, 10, 20, 50],
  }),
  computed: {
    length() {
      return this.customLength
        ? this.customLengthFunc({
            options: this.options,
            page: this.page,
            perPage: this.perPage,
          })
        : Math.ceil(this.data.count / this.perPage);
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.page = 1;
      },
    },
    params: {
      deep: true,
      immediate: true,
      handler({ page, limit }) {
        this.page = page ? +page : 1;
        this.perPage = limit ? +limit : this.perPage;
      },
    },
    perPage(limit) {
      this.page = 1;
      this.$emit('change', { limit, offset: 0 });
      this.utilityMixin_scrollTop();
    },
  },
  methods: {
    onChangePagination(page) {
      this.page = page;
      const offset = (page - 1) * this.perPage;
      this.$emit('change', { page, offset });
      this.utilityMixin_scrollTop();
    },
  },
};
</script>

<style lang="scss" scoped>
.paging-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  position: relative;

  .data-per-page {
    font-size: 13px;
    color: #16325c;

    .per-page-selector {
      width: 72px;
    }
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;

    .data-per-page {
      position: absolute;
      top: 6px;
    }
  }
}
</style>
