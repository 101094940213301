import Vue from 'vue';
import DefaultLayout from '@/layouts/default';
import AuthLayout from '@/layouts/auth';
import EmptyLayout from '@/layouts/empty';
import Card from '@/components/base/BaseCard';
import FormGroup from '@/components/base/BaseFormGroup';
import Wrapper from '@/components/base/BaseWrapper';
import SvgIcon from '@/components/SvgIcon';
import BaseLabel from '@/components/base/BaseLabel';
import BaseAlert from '@/components/base/BaseAlert';
import BaseTableFooter from '@/components/base/BaseTableFooter';
import BaseDialog from '@/components/base/BaseDialog';
import BaseActionButton from '@/components/base/BaseActionButton';
import BaseDataNotFound from '@/components/base/BaseDataNotFound';
import BaseNoData from '@/components/base/BaseNoData';

// Define Layouts
Vue.component('DefaultLayout', DefaultLayout);
Vue.component('EmptyLayout', EmptyLayout);
Vue.component('AuthLayout', AuthLayout);

// Define Global Components
Vue.component('BaseCard', Card);
Vue.component('BaseFormGroup', FormGroup);
Vue.component('BaseTableFooter', BaseTableFooter);
Vue.component('BaseWrapper', Wrapper);
Vue.component('SvgIcon', SvgIcon);
Vue.component('BaseLabel', BaseLabel);
Vue.component('BaseAlert', BaseAlert);
Vue.component('BaseDialog', BaseDialog);
Vue.component('BaseActionButton', BaseActionButton);
Vue.component('BaseDataNotFound', BaseDataNotFound);
Vue.component('BaseNoData', BaseNoData);
