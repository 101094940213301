<template>
  <div :class="{ 'mb-1': !noGutter }">
    <slot
      :attrs="{
        errorMessages: activeErrorMessages,
        success: isValid,
      }"
      :hasErrors="hasErrors"
    />
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  name: 'BaseFormGroup',
  mixins: [singleErrorExtractorMixin],
  props: {
    noGutter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
