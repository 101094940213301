import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import baseWrapper from '@/components/base/BaseWrapper';

Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    redirect: () => ({ name: 'AuthLogin' }),
  },
  {
    path: '/sign-in',
    name: 'AuthLogin',
    meta: { layout: 'auth', requiredNotAuthed: true },
    component: () => import(/* webpackChunkName: "auth-login" */ '@/views/auth/Login'),
  },
  {
    path: '/sign-up',
    name: 'AuthSignUp',
    meta: { layout: 'auth', requiredNotAuthed: true },
    component: () => import(/* webpackChunkName: "auth-signup" */ '@/views/auth/AuthSignUp'),
  },
  {
    path: '/reset-password',
    name: 'AuthResetPassword',
    meta: { layout: 'auth' },
    component: () => import(/* webpackChunkName: "auth-reset-password" */ '@/views/auth/AuthResetPassword'),
  },
  {
    path: '/force-reset-password',
    name: 'ForceResetPassword',
    meta: { layout: 'auth' },
    component: () => import(/* webpackChunkName: "auth-reset-password" */ '@/views/auth/ForceResetPassword'),
  },
  {
    path: '/new-password/:uid/:token',
    name: 'AuthNewPassword',
    meta: { layout: 'auth' },
    component: () => import(/* webpackChunkName: "auth-new-password" */ '@/views/auth/AuthCreateNewPassword'),
  },
  {
    path: '/activation/:uid/:token',
    name: 'AuthEmailActivation',
    meta: { layout: 'auth' },
    component: () => import(/* webpackChunkName: "auth-new-password" */ '@/views/auth/AuthEmailActivation'),
  },
  {
    path: '/account-profile',
    name: 'AccountProfile',
    meta: {
      layout: 'default',
      title: 'title.account_profile',
      icon: 'account-profile',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "account-profile" */ '@/views/account-management/AccountProfile'),
  },
  {
    path: '/account-setting',
    component: baseWrapper,
    children: [
      {
        path: '',
        name: 'AccountSetting',
        meta: {
          layout: 'default',
          title: 'title.account_setting',
          icon: 'account-setting',
          requiredAuth: true,
        },
        component: () => import(/* webpackChunkName: "account-setting" */ '@/views/account-management/AccountSetting'),
      },
      {
        path: '/account-timezone-and-language',
        name: 'AccountTimeAndLang',
        meta: {
          layout: 'default',
          title: 'title.time',
          icon: 'account-setting',
          requiredAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "account-timezone-and-language" */ '@/views/account-management/AccountTimeAndLang'),
      },
      {
        path: '/change-password',
        name: 'AccountChangePassword',
        meta: {
          layout: 'default',
          title: 'title.change_password',
          icon: 'account-setting',
          requiredAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "account-change-password" */ '@/views/account-management/AccountChangePassword'),
      },
      {
        path: '/payment-accounts',
        name: 'PaymentMethods',
        meta: {
          layout: 'default',
          title: 'Payment Accounts',
          icon: 'account-setting',
          requiredAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "account-change-password" */ '@/views/account-management/PaymentMethods'),
      },
    ],
  },

  // Active/Deny Page
  {
    path: '/master-request',
    component: baseWrapper,
    meta: { requiredAuth: true },
    children: [
      {
        path: ':id/approve',
        props: { type: 'approve' },
        meta: { layout: 'auth' },
        component: () => import(/* webpackChunkName: "master-request-approve" */ '@/views/approval'),
      },
      {
        path: ':id/deny',
        props: { type: 'deny' },
        meta: { layout: 'auth' },
        component: () => import(/* webpackChunkName: "master-request-deny" */ '@/views/approval'),
      },
    ],
  },

  // Admin Page
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: 'default',
      title: 'title.dashboard',
      icon: 'menu-dashboard',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard'),
  },
  {
    path: '/deliverable-center',
    name: 'DeliverableCenter',
    meta: {
      layout: 'default',
      title: 'title.deliverable_center',
      icon: 'menu-deliverable-center',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "deliverable-center" */ '@/views/deliverable-center'),
  },
  {
    path: '/sample-tracking',
    component: baseWrapper,
    meta: { requiredAuth: true },
    children: [
      {
        path: '',
        name: 'SampleTracking',
        meta: {
          layout: 'default',
          title: 'title.sample_tracking',
          icon: 'menu-sample-tracking',
        },
        component: () => import(/* webpackChunkName: "sample-tracking" */ '@/views/sample-tracking'),
      },
      {
        path: ':mode/:id/',
        name: 'SampleDetail',
        props: true,
        meta: {
          layout: 'default',
          title: 'title.sample_detail',
          icon: 'sample-detail',
        },
        component: () => import(/* webpackChunkName: "sample-detail" */ '@/views/sample-tracking/SampleDetail'),
      },
    ],
  },
  {
    path: '/result-management',
    component: baseWrapper,
    meta: { requiredAuth: true },
    children: [
      {
        path: '',
        name: 'ResultManagement',
        meta: {
          layout: 'default',
          title: 'title.result_management',
          icon: 'menu-result-management',
        },
        component: () => import(/* webpackChunkName: "result-management" */ '@/views/result-management'),
      },
      {
        path: ':id/comparison',
        name: 'SampleComparison',
        props: true,
        meta: {
          layout: 'default',
          title: 'title.sample_comparison',
          icon: 'sample-comparison',
        },
        component: () => import(/* webpackChunkName: "sample-comparison" */ '@/views/result-management/SampleComparison'),
      },
    ],
  },
  {
    path: '/invoice',
    name: 'Invoice',
    meta: {
      layout: 'default',
      title: 'title.invoice',
      icon: 'menu-invoice',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/invoice'),
  },
  {
    path: '/invoice/pay',
    name: 'PayInvoice',
    meta: {
      layout: 'default',
      title: 'title.invoice',
      icon: 'menu-invoice',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "invoice" */ '@/views/invoice/invoice-payment'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta: {
      layout: 'default',
      title: 'title.notifications',
      icon: 'notifications',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications'),
  },
  {
    path: '/help-center',
    component: baseWrapper,
    meta: { requiredAuth: true },
    children: [
      {
        path: '',
        name: 'HelpCenter',
        meta: {
          layout: 'default',
          title: 'title.faq',
          icon: 'sample-comparison',
        },
        component: () => import(/* webpackChunkName: "help-center" */ '@/views/help-center'),
      },
    ],
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      layout: 'default',
      title: 'title.contact',
      icon: 'menu-contact',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact'),
  },
  {
    path: '/feedback',
    name: 'Feedback',
    meta: {
      layout: 'default',
      title: 'title.feedback',
      icon: 'menu-feedback',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "feedback" */ '@/views/feedback'),
  },
  {
    path: '*',
    name: 'PageNotFound',
    meta: {
      layout: 'default',
      title: 'title.page-not-found',
      icon: 'page-not-found',
      requiredAuth: true,
    },
    component: () => import(/* webpackChunkName: "page-not-found" */ '@/views/page-not-found'),
  },
];

// Uncomment the below routes re-assignment when under maintenance
// routes = [
//   {
//     path: '*',
//     name: 'underMaintenance',
//     meta: {
//       layout: 'empty',
//     },
//     component: () => import(/* webpackChunkName: "page-not-found" */ '@/views/under-maintenance'),
//   },
// ];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (store.getters['Auth/isAuthenticated']) {
      next();
    } else {
      next({ name: 'AuthLogin', query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
  if (to.matched.some(record => record.meta.requiredNotAuthed)) {
    if (store.getters['Auth/isAuthenticated']) {
      next({ name: 'Dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
