const namespaced = true;

const initialState = {
  params: {},
  sampleTracking: {
    next: '',
    previous: '',
    results: [],
    count: 0,
  },
};

const state = {
  stages: [
    { value: 'All Status', display: 'content.btn_status.all_status' },
    { value: 'Checked In', display: 'content.btn_status.sample_tracking.checked_in' },
    { value: 'In Testing', display: 'content.btn_status.sample_tracking.in_testing' },
    { value: 'Reporting', display: 'content.btn_status.sample_tracking.reporting' },
    { value: 'Invoiced', display: 'content.btn_status.sample_tracking.invoiced' },
  ],
  activeParams: null,
  historicalParams: null,
  sampleTypeActive: [],
  sampleTypeHistorical: [],
  sampleActive: { ...initialState.sampleTracking },
  sampleHistorical: { ...initialState.sampleTracking },
  sampleDetail: {},
  sampleDetailTestOrder: [],
  sampleDetailEvaluationOrder: [],
  sampleDetailComparison: [],
  shippingItems: [],
  workOrderOwners: [],
  activeCompany: [],
};

const getters = {
  stages: state => state.stages,
  activeParams: state => state.activeParams,
  historicalParams: state => state.historicalParams,
  sampleTypeActive: state => state.sampleTypeActive,
  sampleTypeHistorical: state => state.sampleTypeHistorical,
  sampleActive: state => state.sampleActive,
  sampleHistorical: state => state.sampleHistorical,
  sampleDetail: state => state.sampleDetail,
  sampleDetailTestOrder: state => state.sampleDetailTestOrder,
  sampleDetailEvaluationOrder: state => state.sampleDetailEvaluationOrder,
  sampleDetailComparison: state => state.sampleDetailComparison,
  shippingItems: state => state.shippingItems,
  workOrderOwners: state => state.workOrderOwners,
  activeCompany: state => state.activeCompany,
};

const mutations = {
  SET_SAMPLE_TYPE_ACTIVE: (state, payload) => {
    state.sampleTypeActive = payload;
  },
  SET_SAMPLE_TYPE_HISTORICAL: (state, payload) => {
    state.sampleTypeHistorical = payload;
  },
  SET_SAMPLE_ACTIVE: (state, payload) => {
    state.sampleActive = payload;
  },
  RESET_SAMPLE_ACTIVE: state => {
    state.sampleActive = { ...initialState.sampleTracking };
  },
  SAMPLE_DETAIL: (state, payload) => {
    state.sampleDetail = payload;
  },
  SAMPLE_DETAIL_TEST_ORDER: (state, payload) => {
    state.sampleDetailTestOrder = payload;
  },
  SAMPLE_DETAIL_EVALUATION_ORDER: (state, payload) => {
    state.sampleDetailEvaluationOrder = payload;
  },
  SAMPLE_DETAIL_EVALUATION_COMPARISON_ORDER: (state, payload) => {
    state.sampleDetailComparison = payload;
  },
  RESET_SAMPLE_DETAIL: state => {
    state.sampleDetail = null;
    state.sampleDetailTestOrder = [];
    state.sampleDetailEvaluationOrder = [];
    state.sampleDetailComparison = [];
  },
  SET_SAMPLE_HISTORICAL: (state, payload) => {
    state.sampleHistorical = payload;
  },
  RESET_SAMPLE_HISTORICAL: state => {
    state.sampleHistorical = { ...initialState.sampleTracking };
  },
  SET_SHIPPING_ITEMS: (state, payload) => {
    state.shippingItems = payload;
  },
  RESET_SHIPPING_ITEMS: state => {
    state.shippingItems = [];
  },
  SET_WORK_ORDER_OWNERS: (state, payload) => {
    state.workOrderOwners = payload;
  },
  RESET_WORK_ORDER_OWNERS: state => {
    state.workOrderOwners = [];
  },
  SET_PARAMS_ACTIVE: (state, payload) => {
    state.activeParams = payload;
  },
  SET_PARAMS_HISTORICAL: (state, payload) => {
    state.historicalParams = payload;
  },
  SET_ACTIVE_COMPANY: (state, payload) => {
    state.activeCompany = payload;
  },
};

const actions = {
  getSampleTypeActive({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/sample_types/current/`)
      .then(res => commit('SET_SAMPLE_TYPE_ACTIVE', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleTypeHistorical({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/sample_types/historical/`)
      .then(res => commit('SET_SAMPLE_TYPE_HISTORICAL', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleTrackingActive({ commit }, params) {
    commit('SET_PARAMS_ACTIVE', params);
    return window.axios
      .get(`/sample_workbench/api/samples/current/`, { params })
      .then(({ data }) => commit('SET_SAMPLE_ACTIVE', data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleTrackingHistorical({ commit }, params) {
    commit('SET_PARAMS_HISTORICAL', params);
    return window.axios
      .get(`/sample_workbench/api/samples/historical/`, {
        params: params,
      })
      .then(res => commit('SET_SAMPLE_HISTORICAL', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleDetail({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/`)
      .then(res => commit('SAMPLE_DETAIL', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleDetailTestOrder({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/tests/`)
      .then(res => commit('SAMPLE_DETAIL_TEST_ORDER', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleDetailEvaluationOrder({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/evaluations/`)
      .then(res => commit('SAMPLE_DETAIL_EVALUATION_ORDER', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleDetailEvaluationComparisonOrder({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/evaluation_comparisons/`)
      .then(res => commit('SAMPLE_DETAIL_EVALUATION_COMPARISON_ORDER', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getSampleDetailAttachment(_, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/attachments/`)
      .then(res => res.data)
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getReports(_, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/reports/`)
      .then(res => res.data)
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getShippingItems({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/shipping_items/`)
      .then(res => {
        commit('SET_SHIPPING_ITEMS', res.data);
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getOrderConfirmations(_, id) {
    return window.axios
      .get(`/sample_workbench/api/samples/${id}/confirmations/`)
      .then(res => res.data)
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  getWorkOrderOwner({ commit }, payload) {
    return window.axios
      .get(`/sample_workbench/api/sample_owners/`, { params: payload })
      .then(res => commit('SET_WORK_ORDER_OWNERS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  createWorkOrderOwner(_, payload) {
    return window.axios
      .post(`/sample_workbench/api/sample_owners/`, payload)
      .then(res => res.data)
      .catch(err => {
        throw new Error(Object.values(err.response?.data?.owner?.address)[0]);
      });
  },

  testOrderRetestRequest(_, payload) {
    return window.axios
      .post(`/sample_workbench/api/request_test_order_retest/`, payload)
      .then(res => res.data)
      .catch(err => {
        throw new Error(Object.values(err.response)[0]);
      });
  },

  getActiveCompany({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/current_associated_companies/`)
      .then(res => commit('SET_ACTIVE_COMPANY', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  removeWorkOrderOwner(_, payload) {
    return window.axios
      .delete('/core/api/work_order_owners/'+payload.pk, { data: payload})
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response.data.owner) {
          throw new Error(Object.values(err.response.data.owner)[0]);
        } else {
          throw new Error(Object.values(err.response.data)[0]);
        }
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
