import { lookup } from 'mime-types';
import { getUrlExtension } from '@/utils';

const namespaced = true;

const state = {
  doc: {},
  picture: null,
};

const getters = {
  doc: state => state.doc,
  picture: state => state.picture,
};

const mutations = {
  SET_DOC: (state, payload) => {
    state.doc = payload;
  },
  RESET_DOC: state => {
    state.doc = {};
  },
  SET_PICTURE: (state, payload) => {
    state.picture = payload;
  },
  RESET_PICTURE: state => {
    state.picture = null;
  },
};

const actions = {
  getData({ commit }, { data, type, params }) {
    const fileUrl = type === 'doc' ? data?.url ?? data?.file_url : data;
    if (fileUrl) {
      const mime = lookup(getUrlExtension(fileUrl));
      return window.axios
        .get(fileUrl, { params: params, responseType: 'blob' })
        .then(res => {
          const blob = new Blob([res.data], { type: mime });
          if (blob.type !== 'false') {
            const fileBlob = URL.createObjectURL(blob);
            if (type === 'doc') {
              commit('SET_DOC', {
                name: data?.name ?? data?.file_name,
                blob: fileBlob,
              });
            } else if (type === 'pic') {
              commit('SET_PICTURE', fileBlob);
            }
          }
          return res.data;
        })
        .catch(err => {
          if (err.response?.data) {
            throw new Error(Object.values(err.response.data)[0]);
          }
        });
    }
  },
};

export default { namespaced, state, getters, mutations, actions };
