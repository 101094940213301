const namespaced = true;

const intialForms = {
  count: 0,
  results: [],
};

const initialParams = {
  offset: 0,
  limit: 10,
};

const state = {
  topics: [],
  topicsSearch: [],
  topicContexts: [],
  topicContextsSearch: [],
  selectedContext: {},
  contextBody: [],
  searchResults: [],
  forms: { ...intialForms },
  formParams: { ...initialParams },
  formSearch: null,
  selfHelpVideos:null
};

const getters = {
  topics: state => state.topics,
  topicsSearch: state => state.topicsSearch,
  topicContexts: state => topic => {
    return state.topicContexts.find(data => data.topic_id === topic.id)?.context ?? [];
  },
  topicContextsSearch: state => topic => {
    return state.topicContextsSearch.find(data => data.topic_id === topic.id)?.context ?? [];
  },
  selectedContext: state => {
    const results = [];
    results.push(state.selectedContext);
    return results;
  },
  contextBody: state => context => {
    return state.contextBody.find(data => data.context_id == context.context_id);
  },
  searchResults: state => state.searchResults,
  forms: state => state.forms,
  formParams: state => state.formParams,
  selfHelpVideos: state => state.selfHelpVideos
};

const mutations = {
  SET_TOPICS: (state, payload) => {
    state.topics = payload;
  },
  RESET_TOPICS: state => {
    state.topics = [];
  },
  SET_TOPIC_CONTEXTS: (state, payload) => {
    state.topicContexts = [...new Set([...state.topicContexts, payload])];
  },
  RESET_TOPIC_CONTEXTS: state => {
    state.topicContexts = [];
  },
  SET_TOPICS_SEARCH: (state, payload) => {
    state.topicsSearch = payload;
  },
  RESET_TOPICS_SEARCH: state => {
    state.topicsSearch = [];
  },
  SET_TOPIC_CONTEXTS_SEARCH: (state, payload) => {
    state.topicContextsSearch = [...new Set([...state.topicContextsSearch, payload])];
  },
  RESET_TOPIC_CONTEXTS_SEARCH: state => {
    state.topicContextsSearch = [];
  },
  SET_SELECTED_CONTEXTS: (state, payload) => {
    state.selectedContext = payload;
  },
  RESET_SELECTED_CONTEXTS: state => {
    state.selectedContext = {};
  },
  SET_CONTEXT_BODY: (state, payload) => {
    state.contextBody = [...new Set([...state.contextBody, payload])];
  },
  RESET_CONTEXT_BODY: state => {
    state.contextBody = [];
  },
  SET_SEARCH_RESULTS: (state, payload) => {
    state.searchResults = payload;
  },
  RESET_SEARCH_RESULTS: state => {
    state.searchResults = [];
  },
  SET_FORMS: (state, payload) => {
    state.forms = payload;
  },
  SET_FORM_PARAMS: (state, payload) => {
    state.formParams = { ...state.formParams, ...payload };
  },
  RESET_FORM_PARAMS: state => {
    state.formParams = { ...initialParams };
  },
  SET_SELF_HELP_VIDEOS: (state, payload) => {
    state.selfHelpVideos = { ...state.formParams, ...payload };
  },
};

const actions = {
  getTopics({ commit }, payload) {
    const search = payload ?? null;
    return window.axios
      .get(`/portal/api/topics/`, { params: { search } })
      .then(res => commit(search ? 'SET_TOPICS_SEARCH' : 'SET_TOPICS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getTopicContexts({ commit }, id) {
    return window.axios
      .get(`/portal/api/topics/${id}/contexts/`)
      .then(res => {
        const topic_id = id;
        const context = res.data;
        commit('SET_TOPIC_CONTEXTS', { topic_id, context });
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getContext({ commit }, id) {
    return window.axios
      .get(`/portal/api/contexts/${id}/`)
      .then(res => commit('SET_SELECTED_CONTEXTS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getContextBody({ commit }, payload) {
    const { context_id } = payload;
    return window.axios
      .get(`/portal/api/contexts/${context_id}/body/`)
      .then(res => {
        const context_body = res.data;
        commit('SET_CONTEXT_BODY', { context_id, context_body });
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  search({ commit }, payload) {
    const { search } = payload;
    return window.axios
      .get(`/portal/api/contexts/`, {
        params: { search },
      })
      .then(res => {
        commit('SET_SEARCH_RESULTS', res.data);
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  fetchForms({ commit, getters }) {
    const params = getters.formParams;
    return window.axios
      .get('/portal/api/forms/', { params })
      .then(res => {
        commit('SET_FORMS', res.data);
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getVideos({ commit }){
  return window.axios
    .get(`/portal/api/self-help-videos/`)
    .then(res => {
      commit('SET_SELF_HELP_VIDEOS', res.data);
    })
    .catch(err => {
      throw new Error(Object.values(err.response.data)[0]);
    });
  }
};

export default { namespaced, state, getters, mutations, actions };
