export let MENUS;

/***********************
Note: This is a temporary fix in order
to disable the invoice on websites other
than staging-portal. This will be returned
once accounting approves of the Invoice
workbench.
**********************/
if(!window.location.href.includes("staging-portal")){
  MENUS = [
    {
      icon: 'menu-dashboard',
      title: 'title.dashboard',
      url: '/dashboard',
    },
    {
      icon: 'menu-deliverable-center',
      title: 'title.deliverable_center',
      url: '/deliverable-center',
    },
    {
      icon: 'menu-sample-tracking',
      title: 'title.sample_tracking',
      url: { name: 'SampleTracking', query: { tab: 'active' } },
    },
    {
      icon: 'menu-result-management',
      title: 'title.result_management',
      url: '/result-management',
    },
    {
      icon: 'menu-invoice',
      title: 'title.invoice',
      url: '/invoice',
    },
    {
      icon: 'menu-contact',
      title: 'title.contact',
      url: '/contact',
    },
    {
      icon: 'menu-feedback',
      title: 'title.feedback',
      url: '/feedback',
    }
  ];
}
else{
  MENUS = [
    {
      icon: 'menu-dashboard',
      title: 'title.dashboard',
      url: '/dashboard',
    },
    {
      icon: 'menu-deliverable-center',
      title: 'title.deliverable_center',
      url: '/deliverable-center',
    },
    {
      icon: 'menu-sample-tracking',
      title: 'title.sample_tracking',
      url: { name: 'SampleTracking', query: { tab: 'active' } },
    },
    {
      icon: 'menu-result-management',
      title: 'title.result_management',
      url: '/result-management',
    },
    {
      icon: 'menu-invoice',
      title: 'title.invoice',
      url: '/invoice',
    },
    {
      icon: 'menu-contact',
      title: 'title.contact',
      url: '/contact',
    },
    {
      icon: 'menu-feedback',
      title: 'title.feedback',
      url: '/feedback',
    }
  ];
}
