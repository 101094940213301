const namespaced = true;

const initialState = {
  params: {},
  results: { next: '', previous: '', results: [], count: 0 },
  testOrdersSelected: { test_id: null, test_orders: [] },
};

const state = {
  associatedCompanies: [],
  associatedEvaluationCompanies: [],
  associatedTests: [],
  associatedEvaluations: [],
  testOrdersParams: { ...initialState.params },
  testOrders: { ...initialState.results },
  evaluationOrdersParams: { ...initialState.params },
  evaluationOrders: { ...initialState.results },
  testOrdersSelected: { ...initialState.testOrdersSelected },
};

const getters = {
  associatedCompanies: state => state.associatedCompanies,
  associatedEvaluationCompanies: state => state.associatedEvaluationCompanies,
  associatedTests: state => state.associatedTests,
  associatedEvaluations: state => state.associatedEvaluations,
  testOrdersParams: state => state.testOrdersParams,
  testOrders: state => state.testOrders,
  evaluationOrdersParams: state => state.evaluationOrdersParams,
  evaluationOrders: state => state.evaluationOrders,
  testOrdersSelected: state => state.testOrdersSelected,
};

const mutations = {
  SET_ASSOCIATED_COMPANIES: (state, payload) => {
    state.associatedCompanies = payload;
  },
  SET_ASSOCIATED_EVALUATION_COMPANIES: (state, payload) => {
    state.associatedEvaluationCompanies = payload;
  },
  SET_ASSOCIATED_TESTS: (state, payload) => {
    state.associatedTests = payload;
  },
  SET_ASSOCIATED_EVALUATIONS: (state, payload) => {
    state.associatedEvaluations = payload;
  },
  SET_PARAMS_TEST_ORDERS: (state, payload) => {
    state.testOrdersParams = { ...state.testOrdersParams, ...payload };
  },
  RESET_PARAMS_TEST_ORDERS: state => {
    state.testOrdersParams = { ...initialState.params };
  },
  SET_TEST_ORDERS: (state, payload) => {
    state.testOrders = payload;
  },
  RESET_TEST_ORDERS: state => {
    state.testOrders = { ...initialState.results };
  },
  SET_PARAMS_EVALUATION_ORDERS: (state, payload) => {
    state.evaluationOrdersParams = {
      ...state.evaluationOrdersParams,
      ...payload,
    };
  },
  RESET_PARAMS_EVALUATION_ORDERS: state => {
    state.evaluationOrdersParams = {
      ...initialState.params,
    };
  },
  SET_EVALUATION_ORDERS: (state, payload) => {
    state.evaluationOrders = payload;
  },
  RESET_EVALUATION_ORDERS: state => {
    state.evaluationOrders = { ...initialState.results };
  },
  SET_TEST_ORDERS_SELECTED: (state, payload) => {
    state.testOrdersSelected = { ...state.testOrdersSelected, ...payload };
  },
  REMOVE_TEST_ORDERS_SELECTED: (state, index) => {
    state.testOrdersSelected.test_orders.splice(index, 1);
  },
  RESET_TEST_ORDERS_SELECTED: state => {
    state.testOrdersSelected = { ...initialState.testOrdersSelected };
  },
  SET_PARAMS_ACTIVE: (state, payload) => {
    state.activeParams = payload;
  },
};

const actions = {
  getAssociatedCompanies({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/historical_associated_companies/`)
      .then(res => commit('SET_ASSOCIATED_COMPANIES', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getAssociatedEvaluationCompanies({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/historical_evaluations_associated_companies/`)
      .then(res => commit('SET_ASSOCIATED_EVALUATION_COMPANIES', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getAssociatedTests({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/associated_tests/`)
      .then(res => commit('SET_ASSOCIATED_TESTS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getAssociatedEvaluations({ commit }) {
    return window.axios
      .get(`/sample_workbench/api/associated_evaluations/`)
      .then(res => commit('SET_ASSOCIATED_EVALUATIONS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getTestOrders({ commit }, params) {
    commit('SET_PARAMS_ACTIVE', params);
    return window.axios
      .get(`/sample_workbench/api/results_management/test_orders/`, { params })
      .then(({ data }) => commit('SET_TEST_ORDERS', data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getEvaluationOrders({ commit }, params) {
    commit('SET_PARAMS_ACTIVE', params);
    return window.axios
      .get(`/sample_workbench/api/results_management/evaluation_orders/`, {
        params,
      })
      .then(({ data }) => commit('SET_EVALUATION_ORDERS', data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
