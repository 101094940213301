<template>
  <div class="canvas">
    <v-card outlined class="custom-card">
      <div class="d-block text-center">
        <!-- <IconNotFound /> -->
        <v-img src="../../assets/images/icon-not-found.png" class="icon-image"> </v-img>
      </div>
      <div class="d-block text-center mt-10 message">
        {{ message }}
      </div>
    </v-card>
  </div>
</template>

<script>
// Icons
// import IconNotFound from "@/assets/svg/icon-not-found.svg";

export default {
  name: 'BaseAlert',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  components: {
    // IconNotFound
  },
  data() {
    return {
      styleObject: {},
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-image {
  width: 180px;
  height: 170px;
  margin-left: auto;
  margin-right: auto;
}
.canvas {
  background-color: #ffffff;
}
.custom-card {
  border: 1px solid #73acd9;
  padding: 100px;
}
.message {
  color: rgba(22, 50, 92, 0.6);
}
</style>
