import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0060AF',
        'primary-light': '#73ACD9',
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        danger: '#C5351C',
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
