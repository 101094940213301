import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
// import * as Cookies from "js-cookie";

Vue.use(Vuex);

const vuexCookie = new VuexPersistence({
  key: 'idfl-session',
  // restoreState: key => Cookies.getJSON(key),
  // saveState: (key, state) => Cookies.set(key, state, { expires: 3 }),
  modules: ['Auth'],
  storage: window.sessionStorage,
});

function loadModules() {
  const context = require.context('./modules', false, /([a-z_]+)\.js$/i);
  const modules = context
    .keys()
    .map(key => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
    .reduce(
      (modules, { key, name }) => ({
        ...modules,
        [name]: context(key).default,
      }),
      {},
    );
  return { context, modules };
}

const { modules } = loadModules();

export default new Vuex.Store({
  modules,
  plugins: [vuexCookie.plugin],
});
