const namespaced = true;

const state = {
  pageable: [
    {
      search: null,
      limit: 10,
      totalPages: 0,
      totalElements: 0,
      currentPage: 1,
      sortBy: null,
      sortDesc: null,
      offset: null,
    },
  ],
  optionList: [10, 50, 100],
};

const getters = {
  defaultPageable: state => {
    return state.pageable[0];
  },
  pageableById: state => id => {
    return state.pageable[id];
  },
  optionList: state => state.optionList,
};

const mutations = {
  SET_DEFAULT_SEARCH: (state, payload) => {
    state.pageable[0].search = payload;
    state.pageable[0].currentPage = 1;
  },

  SET_DEFAULT_LIMIT: (state, payload) => {
    state.pageable[0].limit = payload;
    state.pageable[0].totalPages = Math.ceil(state.pageable[0].totalElements / payload);
    state.pageable[0].currentPage = 1;
  },

  SET_DEFAULT_TOTAL_PAGES: (state, payload) => {
    state.pageable[0].totalPages = payload;
  },
  SET_DEFAULT_OFFSET: (state, payload) => {
    state.pageable[0].offset = payload;
  },
  SET_DEFAULT_TOTAL_ELEMENTS: (state, payload) => {
    state.pageable[0].totalElements = payload;
  },

  SET_DEFAULT_CURRENT_PAGE: (state, payload) => {
    state.pageable[0].currentPage = payload;
  },

  SET_DEFAULT_SORT_BY: (state, payload) => {
    state.pageable[0].sortBy = payload;
  },

  SET_DEFAULT_SORT_DESC: (state, payload) => {
    state.pageable[0].sortDesc = payload;
  },

  RESET_PAGEABLE: state => {
    state.pageable[0].search = '';
    state.pageable[0].limit = 10;
    state.pageable[0].totalPages = 0;
    state.pageable[0].totalElements = 0;
    state.pageable[0].currentPage = 1;
    state.sortBy[0] = null;
    state.sortDesc[0] = null;
  },

  ADD_PAGEABLE: (state, payload) => {
    state.pageable.push(payload);
  },
};

const actions = {};

export default { namespaced, state, getters, mutations, actions };
