<template>
  <!--
  :: Notes
    - if want to display tooltips, you can fill in property "tooltipsText"
      but if you not fill property "tooltipsText", the tooltips will not appear or disabled
    - if want to action button, set "withDropdown" props with true
      set dropdownItems with array of object, key "title" on object is required
      and "selectedItem" it's optional param
  -->
  <div class="action-button d-inline">
    <v-tooltip v-if="!withDropdown" top color="primary-light" :disabled="tooltipsText == null">
      <template #activator="{ on, attrs }">
        <v-btn class="no-transform" :disabled="disabled" icon v-bind="attrs" v-on="on" @click="click()">
          <!-- Slot for icon or text button -->
          <slot />
        </v-btn>
      </template>
      <div v-html="tooltipsText" />
    </v-tooltip>

    <v-menu v-else>
      <template #activator="{ on: menu, attrs }">
        <v-tooltip top color="primary-light" :disabled="tooltipsText == null">
          <template #activator="{ on: tooltip }">
            <v-btn class="no-transform" :disabled="disabled" icon v-bind="attrs" @click="click()" v-on="{ ...tooltip, ...menu }">
              <slot />
            </v-btn>
          </template>
          <span>{{ tooltipsText }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in dropdownItems" :key="index">
          <v-btn text @click="clickDropdown(item)" class="no-transform">
            {{ item.title }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'BaseActionButton',
  props: {
    dropdownItems: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipsText: {
      type: String,
      default: null,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    withDropdown: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
    clickDropdown(dropdownItem) {
      const selectedItem = this.selectedItem;
      this.$emit('clickDropdown', { selectedItem, dropdownItem });
    },
  },
};
</script>
<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}
</style>
