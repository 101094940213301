const namespaced = true;

const initialValues = {
  offices: []
};

const state = {
  offices: { ...initialValues },
};

const actions = {
  fetchOffices() {
    return window.axios
      .get('/employee_workbench/api/offices/')
      .then(res => {
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

};

export default { namespaced, state, actions };
