<template>
  <transition name="fade">
    <div v-if="show" class="fab-button" @click="utilityMixin_scrollTop">
      <v-icon size="16" color="#fff">mdi-arrow-up</v-icon>
    </div>
  </transition>
</template>

<script>
import utilityMixin from '@/mixins/utilityMixin';

export default {
  name: 'BaseScrollTop',
  mixins: [utilityMixin],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler() {
        this.utilityMixin_scrollTop();
        setTimeout(() => {
          this.show = false;
        }, 300);
      },
    },
  },
  methods: {
    onScrolling() {
      this.show = window.pageYOffset >= 100;
    },
  },
  created() {
    window.addEventListener('scroll', this.onScrolling);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScrolling);
  },
};
</script>

<style lang="scss" scoped>
@keyframes float {
  0% {
    box-shadow: 0 3px 15px 0px rgba(0, 128, 222, 0.8);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 5px 15px 0px rgba(0, 128, 222, 0.8);
    transform: translatey(-3px);
  }
  100% {
    box-shadow: 0 3px 15px 0px rgba(0, 128, 222, 0.8);
    transform: translatey(0px);
  }
}

.fab-button {
  background: #0060af;
  border: 1px solid #0060af;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 999;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  overflow: hidden;
  animation: float 1.3s ease-in-out infinite;
  box-shadow: 0 3px 15px 0px rgba(0, 128, 222, 0.8);

  &:hover {
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
