import { lookup } from 'mime-types';
import { getUrlExtension } from '@/utils';

const namespaced = true;

const initialState = {
  testOrders: { count: 0, next: '', previous: '', results: [] },
  params: { test_id: null, test_orders: null },
};

const state = {
  params: { ...initialState.params },
  title: {},
  information: [],
  average: [],
  testOrders: { ...initialState.testOrders },
  testOrdersSelected: [],
};

const getters = {
  params: state => state.params,
  title: state => state.title,
  information: state => {
    const rebuild = arr => {
      try {
        const obj = {};
        arr.forEach(info => {
          const { name, template_key } = info;
          obj[template_key] = name;
        });
        return obj;
      } catch {
        return arr;
      }
    };
    return rebuild(state.information);
  },
  average: state => {
    const rebuild = arr => {
      const obj = {};
      try {
        arr.forEach(info => {
          const { result, template_key } = info;
          obj[template_key] = result;
        });
        return obj;
      } catch {
        return arr;
      }
    };
    return rebuild(state.average);
  },
  testOrders: state => state.testOrders,
  testOrdersSelected: state => {
    const testOrders = state.testOrdersSelected.map(data => {
      const rebuild = arr => {
        const obj = {};
        try {
          arr.forEach(data => {
            const { result, template_key } = data;
            obj[template_key] = result;
          });
          return obj;
        } catch {
          return arr;
        }
      };
      if (data.results) data.results = rebuild(data.results);
      if (data.id) data.id = parseInt(data.id);
      return data;
    });
    return testOrders;
  },
};

const mutations = {
  SET_PARAMS: (state, payload) => {
    state.params = { ...state.params, ...payload };
  },
  SET_TITLE: (state, payload) => {
    state.title = payload;
  },
  SET_INFORMATION: (state, payload) => {
    state.information = payload;
  },
  SET_AVERAGE: (state, payload) => {
    state.average = payload;
  },
  SET_TEST_ORDERS: (state, payload) => {
    state.testOrders = payload;
  },
  ADD_TEST_ORDER: (state, payload) => {
    state.testOrdersSelected = [...state.testOrdersSelected, payload];
  },
  EDIT_TEST_ORDER: (state, payload) => {
    state.testOrdersSelected = [
      ...state.testOrdersSelected.map(order => (order._rand !== payload._rand ? order : { ...order, ...payload })),
    ];
  },
  DEL_TEST_ORDER: (state, payload) => {
    const index = state.testOrdersSelected.findIndex(ord => ord._rand === payload._rand);
    if (index > -1) state.testOrdersSelected.splice(index, 1);
  },
  RESET_ALL: state => {
    state.params = { ...initialState.params };
    state.title = {};
    state.information = [];
    state.average = [];
    state.testOrders = { ...initialState.testOrders };
    state.testOrdersSelected = [];
  },
};

const actions = {
  getTestTitle({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/comparison/tests/${id}/`)
      .then(res => commit('SET_TITLE', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getTestOrders({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/comparison/tests/${id}/test_orders/`)
      .then(res => commit('SET_TEST_ORDERS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getTestInformation({ commit }, id) {
    return window.axios
      .get(`/sample_workbench/api/comparison/tests/${id}/produced_results/`)
      .then(res => commit('SET_INFORMATION', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getTestOrderProducedResults({ commit }, payload) {
    const _rand = payload._rand ?? Math.random();
    const id = payload.id;
    commit(payload._rand ? 'EDIT_TEST_ORDER' : 'ADD_TEST_ORDER', {
      _rand,
      id,
      loading_image: true,
      loading_results: true,
    });
    let image = null;
    let results = null;

    window.axios
      .get(`/sample_workbench/api/comparison/test_orders/${payload.id}/`)
      .then(res => {
        results = res.data.produced_results ?? null;
        new Promise((resolve, reject) => {
          if (res.data.image_url) {
            const mime = lookup(getUrlExtension(res.data.image_url));
            return window.axios
              .get(res.data.image_url, { responseType: 'blob' })
              .then(res => {
                const blob = new Blob([res.data], { type: mime });
                if (blob.type !== 'false') {
                  resolve(URL.createObjectURL(blob));
                }
                reject(blob.type);
              })
              .catch(err => reject(err));
          }
          reject(null);
        })
          .then(img => (image = img))
          .catch(err => console.error(Object.values(err.response.data)[0]))
          .finally(() => {
            commit('EDIT_TEST_ORDER', {
              _rand,
              id,
              image,
              loading_image: false,
            });
          });
      })
      .catch(err => console.error(Object.values(err.response.data)[0]))
      .finally(() =>
        commit('EDIT_TEST_ORDER', {
          _rand,
          id,
          results,
          loading_results: false,
        }),
      );
  },
  getAverage({ commit, rootGetters }) {
    const params = rootGetters['SampleComparison/params'];
    return window.axios
      .get(`/sample_workbench/api/comparison/average/`, {
        params: params,
      })
      .then(res => commit('SET_AVERAGE', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
