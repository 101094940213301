<template>
  <div
    class="label indigo--text"
    :class="{
      'label-small': isSmall,
      'label-large': isLarge,
      'label-default': !isSmall || !isLarge,
    }"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'BaseLabel',
  props: {
    title: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    isSmall() {
      return this.fontSize === 'small';
    },
    isLarge() {
      return this.fontSize === 'large';
    },
  },
};
</script>

<style>
.label {
  font-weight: bold;
}

.label-default {
  font-size: 12px;
  margin-bottom: 5px;
}

.label-small {
  font-size: 14px;
  margin-bottom: 8px;
}

.label-large {
  font-size: 18px;
  margin-bottom: 15px;
}
</style>
