var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-button d-inline"},[(!_vm.withDropdown)?_c('v-tooltip',{attrs:{"top":"","color":"primary-light","disabled":_vm.tooltipsText == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-transform",attrs:{"disabled":_vm.disabled,"icon":""},on:{"click":function($event){return _vm.click()}}},'v-btn',attrs,false),on),[_vm._t("default")],2)]}}],null,true)},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.tooltipsText)}})]):_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary-light","disabled":_vm.tooltipsText == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-transform",attrs:{"disabled":_vm.disabled,"icon":""},on:{"click":function($event){return _vm.click()}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipsText))])])]}}])},[_c('v-list',_vm._l((_vm.dropdownItems),function(item,index){return _c('v-list-item',{key:index},[_c('v-btn',{staticClass:"no-transform",attrs:{"text":""},on:{"click":function($event){return _vm.clickDropdown(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }