const namespaced = true;

const state = {
  profile: {},
  listMaster: [],
  offices: [],
  services: [],
};

const getters = {
  profile: state => state.profile,
  listMaster: state => state.listMaster,
  offices: state => state.offices,
  services: state => state.services,
};

const mutations = {
  SET_PROFILE: (state, payload) => {
    state.profile = payload;
  },
  SET_LIST_MASTER: (state, payload) => {
    state.listMaster = payload;
  },
  SET_OFFICES: (state, payload) => {
    state.offices = payload;
  },
  SET_SERVICES: (state, payload) => {
    state.services = payload;
  },
};

const actions = {
  getProfile({ commit }, id) {
    return window.axios
      .get(`/user_account_profile/api/customer-user-profiles/${id}/`)
      .then(res => {
        commit('SET_PROFILE', res.data);
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  // eslint-disable-next-line no-unused-vars
  getCompany(_ctx) {
    return window.axios
      .get(`/user_account_profile/api/company-relationships/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  // eslint-disable-next-line no-unused-vars
  requestMaster({ commit }, id) {
    return window.axios
      .get(`user_account_profile/api/master-accounts/${id}/find/`)
      .then(res => {
        commit('SET_LIST_MASTER', res.data);
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  // eslint-disable-next-line no-unused-vars
  sendRequestMaster({ commit }, payload) {
    return window.axios
      .post(`/user_account_profile/api/master-accounts/send/`, payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  // eslint-disable-next-line no-unused-vars
  approvalRequestMaster({ commit }, { type, id }) {
    return window.axios.get(`user_account_profile/api/master-accounts/${id}/${type}/`).then(res => {
      return res.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  patchAccountProfile({ commit }, data) {
    return window.axios
      .put(`/user_account_profile/api/customer-user-profiles/${data.id}/`, data.form)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  // eslint-disable-next-line no-unused-vars
  patchMe({ commit }, data) {
    return window.axios
      .patch(`/auth/users/me/`, data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },

  fetchOffices({ commit }) {
    return window.axios.get('/employee_workbench/api/offices/').then(({ data }) => {
      commit('SET_OFFICES', data);
      return data;
    });
  },

  fetchServices({ commit }, id) {
    return window.axios.get(`/employee_workbench/api/offices/${id}/contact_info/`).then(({ data }) => {
      commit('SET_SERVICES', data);
      return data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  verifyMyCompany({ commit }, id) {
    return window.axios
      .post('/user_account_profile/api/customer-user-profiles/verify/', {
        office_contact_info_id: id,
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
