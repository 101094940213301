const namespaced = true;

const initialValues = {
  count: 0,
  results: [],
};

const initialParams = {
  offset: 0,
  limit: 10,
};

const state = {
  notifications: { ...initialValues },
  notificationParams: { ...initialParams },
  notificationLoading: false,
  notificationDetail: {},
  notificationUnreads: 0,
  notificationDetailLoading: false,
  currentNotification: null,
};

const getters = {
  notifications: state => state.notifications,
  notificationParams: state => state.notificationParams,
  notificationUnreads: state => state.notificationUnreads,
  notificationLoading: state => state.notificationLoading,
  notificationDetail: state => state.notificationDetail,
  notificationDetailLoading: state => state.notificationDetailLoading,
  currentNotification: state => state.currentNotification,
};

const mutations = {
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  },
  SET_NOTIFICATION_LOADING: (state, payload) => {
    state.notificationLoading = payload;
  },
  SET_NOTIFICATION_DETAIL: (state, payload) => {
    state.notificationDetail = payload;
  },
  SET_NOTIFICATION_DETAIL_LOADING: (state, payload) => {
    state.notificationDetailLoading = payload;
  },
  UPDATE_NOTIFICATION_READ: (state, { id, read_at }) => {
    const index = state.notifications.results.findIndex(notif => notif.id === id);
    if (index !== -1) state.notifications.results[index].read_at = read_at;
  },
  SET_NOTIFICATION_PARAMS: (state, payload) => {
    state.notificationParams = { ...state.notificationParams, ...payload };
  },
  RESET_NOTIFICATION_PARAMS: state => {
    state.notificationParams = { ...initialParams };
  },
  SET_CURRENT_NOTIFICATION: (state, payload) => {
    state.currentNotification = payload;
  },
  SET_NOTIFICATION_UNREADS: (state, payload) => {
    state.notificationUnreads = payload;
  },
};

const actions = {
  fetchNotifications({ commit, getters }) {
    commit('SET_NOTIFICATION_LOADING', true);

    const params = getters.notificationParams;
    return window.axios
      .get('/portal/api/notifications/', { params })
      .then(res => {
        commit('SET_NOTIFICATION_DETAIL', null);
        commit('SET_NOTIFICATIONS', res.data);
        commit('SET_NOTIFICATION_LOADING', false);
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      })
      .finally(() => {
        commit('SET_NOTIFICATION_LOADING', false);
      });
  },

  fetchNotificationUnreads({ commit }) {
    return window.axios
      .get('/portal/api/notifications/', { params: { read_at: 2 } })
      .then(res => {
        commit('SET_NOTIFICATION_UNREADS', res.data.count);
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      })
      .finally(() => {});
  },

  fetchNotification({ commit }, id) {
    commit('SET_NOTIFICATION_DETAIL_LOADING', true);

    return window.axios
      .get(`/portal/api/notifications/${id}`)
      .then(res => {
        commit('SET_NOTIFICATION_DETAIL', res.data);
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      })
      .finally(() => {
        commit('SET_NOTIFICATION_DETAIL_LOADING', false);
      });
  },

  updateNotificationRead({ commit, rootGetters }, { id, read_at }) {
    const recipient = rootGetters['Auth/user'].id;
    return window.axios
      .put(`portal/api/notifications/${id}/`, { recipient, read_at })
      .then(res => {
        commit('UPDATE_NOTIFICATION_READ', { id, read_at });
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data));
      });
  },

  deleteNotification({ dispatch, getters }) {
    const id = getters.notificationDetail.id;
    return window.axios
      .put(`portal/api/notifications/${id}/`, { is_deleted: true })
      .then(res => {
        dispatch('fetchNotifications');
        return res;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data));
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
