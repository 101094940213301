import uniqBy from 'lodash/uniqBy';

const namespaced = true;

const initialState = {
  stageStatus: [
    {
      value: 'No Payments Allocated',
      display: 'content.btn_status.invoice.outstanding',
    },
    {
      value: 'Partially Paid',
      display: 'content.btn_status.invoice.partially_paid',
    },
  ],
  params: {},
  invoice: {
    next: '',
    previous: '',
    results: [],
    count: 0,
  },
  invoicesSelected: { invoices: [] },
};

const state = {
  stageStatus: [...initialState.stageStatus],
  invoicesUnpaid: { ...initialState.invoice },
  invoicesPaid: { ...initialState.invoice },
  companiesUnpaid: [],
  companiesPaid: [],
  currenciesUnpaid: [],
  currenciesPaid: [],
  invoiceOwners: [],
  invoicesSelected: [],
  invoiceDetail: {},
  invoiceFiles: [],
  paysimpleInfo:[]
};

const getters = {
  stageStatus: state => state.stageStatus,
  invoicesUnpaid: state => state.invoicesUnpaid,
  invoicesPaid: state => state.invoicesPaid,
  companiesUnpaid: state => state.companiesUnpaid,
  companiesPaid: state => state.companiesPaid,
  currenciesUnpaid: state => state.currenciesUnpaid,
  currenciesPaid: state => state.currenciesPaid,
  invoicesSelected: state => state.invoicesSelected,
  invoiceOwners: state => state.invoiceOwners,
  invoiceDetail: state => state.invoiceDetail,
  invoiceFiles: state => state.invoiceFiles,
  paysimpleInfo: state => state.paysimpleInfo,
};

const mutations = {
  SET_INVOICES_UNPAID: (state, payload) => {
    state.invoicesUnpaid = payload;
  },
  RESET_INVOICES_UNPAID: state => {
    state.invoicesUnpaid = { ...initialState.invoice };
  },
  SET_INVOICES_PAID: (state, payload) => {
    state.invoicesPaid = payload;
  },
  RESET_INVOICES_PAID: state => {
    state.invoicesPaid = { ...initialState.invoice };
  },
  SET_COMPANIES_UNPAID: (state, payload) => {
    state.companiesUnpaid = payload;
  },
  RESET_COMPANIES_UNPAID: state => {
    state.companiesUnpaid = [];
  },
  SET_COMPANIES_PAID: (state, payload) => {
    state.companiesPaid = payload;
  },
  RESET_COMPANIES_PAID: state => {
    state.companiesPaid = [];
  },
  SET_CURRENCIES_UNPAID: (state, payload) => {
    state.currenciesUnpaid = payload;
  },
  RESET_CURRENCIES_UNPAID: state => {
    state.currenciesUnpaid = [];
  },
  SET_CURRENCIES_PAID: (state, payload) => {
    state.currenciesPaid = payload;
  },
  RESET_CURRENCIES_PAID: state => {
    state.currenciesPaid = [];
  },
  SET_INVOICES_SELECTED: (state, payload) => {
    state.invoicesSelected = uniqBy([...state.invoicesSelected, ...payload], 'id');
  },
  UPDATE_INVOICES_SELECTED: (state, payload) => {
    state.invoicesSelected = payload;
  },
  REMOVE_INVOICES_SELECTED: (state, index) => {
    state.invoicesSelected.splice(index, 1);
  },
  RESET_INVOICES_SELECTED: state => {
    state.invoicesSelected = [];
  },
  SET_INVOICE_OWNERS: (state, payload) => {
    state.invoiceOwners = payload;
  },
  RESET_INVOICE_OWNERS: state => {
    state.invoiceOwners = [];
  },
  SET_INVOICE_DETAIL: (state, payload) => {
    state.invoiceDetail = payload;
  },
  SET_INVOICE_FILES: (state, payload) => {
    state.invoiceFiles = payload;
  },
  RESET_INVOICE_FILES: state => {
    state.invoiceFiles = [];
  },
  SET_PAYSIMPLE_INFO: (state, payload) =>{
    state.paysimpleInfo=payload
  }
};

const actions = {
  getInvoices({ commit }, params) {
    const query = params.query;
    return window.axios
      .get(`/invoicing_workbench/api/invoices/${params.status}/`, {
        params: query,
      })
      .then(({ data }) => {
        if (params.status === 'paid') {
          commit('SET_INVOICES_PAID', data);
        } else {
          commit('SET_INVOICES_UNPAID', data);
        }
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  createInvoiceOwner(_context, payload) {
    return window.axios
      .post(`/invoicing_workbench/api/invoices/${payload.object_id}/owners/`, payload)
      .then(res => res.data)
      .catch(err => {
        throw new Error(Object.values(err.response?.data?.owner?.address)[0]);
      });
  },
  getInvoiceOwner({ commit }, id) {
    return window.axios
      .get(`/invoicing_workbench/api/invoices/${id}/owners/`)
      .then(res => commit('SET_INVOICE_OWNERS', res.data))
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  removeInvoiceOwner(_, payload) {
    return window.axios
      .delete('/invoicing_workbench/api/invoice_owners/'+payload.pk, { data: payload})
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response.data.owner) {
          throw new Error(Object.values(err.response.data.owner)[0]);
        } else {
          throw new Error(Object.values(err.response.data)[0]);
        }
      });
  },
  getInvoiceFiles({ commit }, id) {
    return window.axios
      .get(`/invoicing_workbench/api/invoices/${id}/files/`)
      .then(({ data }) => {
        commit('SET_INVOICE_FILES', data);
        return data;
      })

      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getCompanies({ commit }, param) {
    return window.axios
      .get(`/invoicing_workbench/api/${param}_associated_companies/`)
      .then(res => {
        if (param === 'paid') {
          commit('SET_COMPANIES_PAID', res.data);
        } else {
          commit('SET_COMPANIES_UNPAID', res.data);
        }
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getCurrencies({ commit }, param) {
    return window.axios
      .get(`/invoicing_workbench/api/${param}_currencies/`)
      .then(res => {
        if (param === 'paid') {
          commit('SET_CURRENCIES_PAID', res.data);
        } else {
          commit('SET_CURRENCIES_UNPAID', res.data);
        }
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  getPaysimpleInfo({ commit }) {
    return window.axios
      .get(`/user_account_profile/api/get_paysimple_id/`)
      .then(res => {
        commit('SET_PAYSIMPLE_INFO', res.data.results);
        return res.data.results;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  postPaymentData(_, form_data){
    return window.axios
      .post(`/invoicing_workbench/api/submit_payment/`, form_data)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      });
  },
  postBankData(_, form_data){
    form_data.account_type="ach"
    return window.axios
      .post(`/invoicing_workbench/api/submit_account/`, form_data)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err)
      });
  },
  postCardData(_, form_data){
    form_data.account_type="creditcard"
    return window.axios
      .post(`/invoicing_workbench/api/submit_account/`, form_data)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      });
  },
  deleteAccount(_, id){
    return window.axios
      .post(`/invoicing_workbench/api/delete_payment/`, {"id":id} )
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      });
  },
  makeDefault(_, id){
  return window.axios
    .post(`/invoicing_workbench/api/make_default/`, {"id":id} )
    .then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    });
  },
};

export default { namespaced, state, getters, mutations, actions };
