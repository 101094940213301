import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const options = {
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  position: 'top-right',
  timeout: 5000,
};

Vue.use(Toast, options);
