<template>
  <v-dialog
    v-model="dialog"
    persistent
    :retain-focus="false"
    max-width="70%"
    class="p-3"
    :width="width"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="rounded-lg">
      <v-list-item three-line>
        <v-list-item-content>
          <v-row no-gutters>
            <v-col cols="11">
              <v-list-item-title class="ttl-color ttl headline mb-1 ml-2 text-wrap">
                {{ title }}
              </v-list-item-title>
              <v-list-item-subtitle class="ttl-color sttl ml-2">
                {{ subTitle }}
              </v-list-item-subtitle>
            </v-col>
            <v-col cols="1">
              <div class="float-right">
                <v-btn icon @click="close()" class="float-right no-transform">
                  <svg-icon icon="icon-close" />
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <!-- Dialog content -->
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    subTitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}

.ttl-color {
  color: #16325c;
}

.ttl {
  font-family: Arial;
  font-size: 22px;
  font-weight: bold;
}

.sttl {
  font-family: ArialMT;
  font-size: 13px;
}
</style>
