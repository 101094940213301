<template>
  <!--
  :: Notes
    - This component is used for alerts and confirm dialogs
    - In this component there several parameters
      1. type: used to select type, dialog alert or confirm dialog
        if you want to displays an alert dialog, fill it with "alert"
        if you want to display an confirm dialog, fill with "confirm"
        if this parameter is not filled, it will display an alert dialog by default
      2. title:
        if title is not filled, it will not display title
      3. message:
        if message is not filled, it will not display message
      4. btnAgreeText:
        if btnAgreeText is not filled, it will display "Yes"
      5. btnCancelText:
        if btnCancelText is not filled, it will display "No"
      6. icon:
        if icon is not filled, it will not display icon
        icon available: success, danger, send


      # example confirm
        if (
          this.$refs.alert.open({
            type: "confirm",
            title: "Delete",
            message: `are you sure want to delete ${item.name}`,
            icon: "danger"
          })
        ) {
          // action delete data
        }

      # example alert
        this.$refs.alert.open({
          type: "alert",
          title: "Success!",
          message: "success delete data",
          icon: "success"
        })
  -->
  <v-dialog v-model="dialog" :persistent="type === 'confirm' ? true : false" max-width="500">
    <v-card class="pa-2">
      <div v-if="type === 'confirm'" class="float-right">
        <v-btn icon @click.native="cancel" class="float-right no-transform">
          <SvgIcon icon="icon-close" />
        </v-btn>
      </div>
      <div class="pt-10 text-center">
        <SvgIcon icon="icon-success" v-if="icon === 'success'" />
        <SvgIcon icon="icon-danger" v-else-if="icon === 'danger'" />
        <SvgIcon icon="icon-send" v-else-if="icon === 'send'" />
        <SvgIcon icon="icon-upgrade" v-else-if="icon === 'upgrade'" />
        <SvgIcon icon="icon-expired" v-else-if="icon === 'expired'" />
        <SvgIcon icon="icon-no-shipping" v-else-if="icon === 'no-shipping'" />
        <SvgIcon icon="icon-retest-confirm" v-else-if="icon === 'retest'" />
        <SvgIcon icon="icon-no-order" v-else-if="icon === 'no-order'" />
        <SvgIcon icon="icon-no-reports" v-else-if="icon === 'no-reports'" />
        <SvgIcon icon="icon-no-submission" v-else-if="icon === 'no-submission'" />
        <SvgIcon icon="icon-delete" v-if="icon === 'delete'" />
      </div>
      <v-card-title class="headline d-block text-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-center pb-8">
        {{ message }}
      </v-card-text>

      <v-card-actions v-if="type === 'confirm'" class="pb-5">
        <v-spacer></v-spacer>
        <v-btn v-if="cancelBtn" class="px-4 no-transform" color="primary" outlined depressed @click.native="cancel">
          <span class="mx-8">
            {{ btnCancelText }}
          </span>
        </v-btn>
        <v-btn class="px-4 no-transform" color="primary" depressed @click.native="agree">
          <span class="mx-8">
            {{ btnAgreeText }}
          </span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuthDialog',
  data() {
    return {
      btnAgreeText: 'Yes',
      btnCancelText: 'No',
      dialog: false,
      message: null,
      icon: '',
      resolve: null,
      reject: null,
      title: null,
      type: 'alert',
      cancelBtn: true,
    };
  },
  methods: {
    open(options) {
      this.dialog = true;
      this.btnAgreeText = options.btnAgreeText ? options.btnAgreeText : 'Yes';
      this.btnCancelText = options.btnCancelText ? options.btnCancelText : 'No';
      this.message = options.message ? options.message : '';
      this.icon = options.icon ? options.icon.toLowerCase() : '';
      this.title = options.title ? options.title : '';
      this.type = options.type ? options.type.toLowerCase() : 'alert';
      this.cancelBtn = options.cancelBtn;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}
</style>
