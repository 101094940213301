<template>
  <img v-on="$listeners" v-bind="$attrs" :src="svg" :style="styleBaseSvg" :class="styleClassBaseSvg" />
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    svg() {
      return require(`@/assets/svg/${this.icon}.svg`);
    },
    styleBaseSvg() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    styleClassBaseSvg() {
      return {
        'responsive-img': this.isResponsive,
        'cursor-pointer': this.isCursor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-img {
  max-width: 100%;
  width: auto;
  height: auto;
}
</style>
