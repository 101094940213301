<template>
  <v-app class="bg">
    <v-main class="arialMT">
      <v-container fluid class="pa-0 full-height">
        <v-layout fill-height>
          <router-view />
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout',
  components: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #f1f5f8 !important;
}
.full-height {
  height: 100vh;
}
</style>
