<template>
  <v-card class="elevation-0">
    <v-card-title v-if="!!title" class="text-subtitle-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
