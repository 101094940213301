const namespaced = true;

const initialState = {
  deliverables: {
    highest_count: 0,
    overall_total: 0,
    next: '',
    previous: '',
    results: [],
  },
};

const state = {
  types: [],
  deliverables: { ...initialState.deliverables },
  usersDeliverables: [],
};

const getters = {
  types: state => state.types,
  deliverables: state => state.deliverables,
  usersDeliverables: state => state.usersDeliverables,
};

const mutations = {
  SET_DELIVERABLES: (state, payload) => {
    state.deliverables = payload;
  },
  RESET_DELIVERABLES: state => {
    state.deliverables = { ...initialState.deliverables };
  },
  SET_USERS_DELIVERABLES: (state, payload) => {
    state.usersDeliverables = payload;
  },
  RESET_USERS_DELIVERABLES: state => {
    state.usersDeliverables = [];
  },
  SET_TYPES: (state, payload) => {
    state.types = payload;
  },
  RESET_TYPES: state => {
    state.types = [];
  },
};

const actions = {
  fetchAll({ commit }, params) {
    return window.axios
      .get(`/deliverable/api/deliverables/`, { params })
      .then(({ data }) => {
        commit('SET_DELIVERABLES', data);
        return data;
      })
      .catch(err => {
        throw new Error(Object.values(err.response.data)[0]);
      });
  },
  fetchUsersDeliverable({ commit }, payload) {
    return window.axios
      .get(`/deliverable/api/deliverable-owners/`, {
        params: payload,
      })
      .then(response => {
        commit('SET_USERS_DELIVERABLES', response.data);
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
  fetchTypes({ commit }) {
    return window.axios
      .get(`/deliverable/api/deliverable-content-types/`)
      .then(response => {
        commit('SET_TYPES', response.data);
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
  createDeliverableOwner(_, payload) {
    return window.axios
      .post('/deliverable/api/deliverable-owners/', payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response.data.owner) {
          throw new Error(Object.values(err.response.data.owner)[0]);
        } else {
          throw new Error(Object.values(err.response.data)[0]);
        }
      });
  },
  removeDeliverableOwner(_, payload) {
    return window.axios
      .delete('/deliverable/api/deliverable-owners/'+payload.pk+'/', { data: payload})
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response.data.owner) {
          throw new Error(Object.values(err.response.data.owner)[0]);
        } else {
          throw new Error(Object.values(err.response.data)[0]);
        }
      });
  },
  sendDeliverableByEmail(_, payload) {
    return window.axios
      .post('/deliverable/api/deliverable-attachment/email/', payload)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(err.response.data);
      });
  },
};

export default { namespaced, state, getters, mutations, actions };
