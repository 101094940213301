import Vue from 'vue';
import * as http from 'axios';
import store from '../store';
import router from '../router';

let config = { baseURL: process.env.VUE_APP_BASE_URL ?? '//api.local/' };

const axios = http.create(config);
axios.interceptors.request.use(
  config => {
    if (store.getters['Auth/isAuthenticated']) {
      config.headers.Authorization = `Token ${store.getters['Auth/token']}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

// Sesuaikan dengan kondisi API
axios.interceptors.response.use(undefined, error => {
  const errorResponse = error?.response;
  const originalRequest = error?.config;

  if (errorResponse?.status === 401 && originalRequest.url === 'api/auth/refresh') {
    // Redirect To Login
    router.push({ name: 'AuthLogin' }).catch();
    return Promise.reject(error);
  }

  if (errorResponse?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    store
      .dispatch('Auth/refresh')
      .then(({ token }) => {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        return axios(originalRequest);
      })
      .catch(() => {
        // store.dispatch("Auth/clearSession");
        router.push({ name: 'AuthLogin' }).catch();
        return Promise.reject();
      });
  }
  // Server Maintenance
  else if (errorResponse?.status === 502) {
    // Redirect To Maintenance Page
  } else if (errorResponse?.status === 503) {
    // Redirect To Server Busy Page
  } else if (errorResponse?.status === 403) {
    // Redirect To Forbidden Page
  }

  return Promise.reject(error);
});

Plugin.install = function(Vue) {
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
